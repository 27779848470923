<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <img
            src="../../../../assets/img/pro_fan.png"
            @click="$router.replace('/vipCenter/withdraw')"
            class="pro_fan"
            alt=""
          />
          <div class="titles">提现记录</div>
          <div class="center_top">
            <div class="top_4">筛选时间:</div>
            <div class="top_5">
              <el-date-picker
                :editable="false"
                v-model="valueStart"
                type="date"
                placeholder="开始时间"
                @change="chooseStart"
              >
              </el-date-picker>
            </div>
            <div class="top_6"></div>
            <div class="top_5">
              <el-date-picker
                :editable="false"
                v-model="valueEnd"
                type="date"
                placeholder="结束时间"
                @change="chooseEnd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="list_head">
            <div class="list_ever">订单编号</div>
            <div class="list_ever">提现时间</div>
            <div class="list_ever">明细</div>
            <div class="list_ever">金额</div>
            <div class="list_ever2">状态</div>
          </div>
          <div class="list">
            <div
              class="loop"
              v-for="(item, index) in record"
              :key="index"
              @click="xiansuc(item.id)"
            >
              <div class="loop_over">{{ item.num }}</div>
              <div class="loop_over">
                {{ (item.create_at * 1000) | format("YYYY-MM-DD") }}
              </div>
              <div class="loop_over">{{ item.bank }} {{ item.bank_num }}</div>
              <div
                :class="
                  parseFloat(item.amount / 100) < 0
                    ? 'loop_over reds'
                    : 'loop_over'
                "
              >
                {{ item.amount / 100 }}
              </div>
              <div
                class="loop_over2"
                style="color: #ff411d"
                v-show="item.status == 0"
              >
                审核中
              </div>
              <div
                class="loop_over2"
                style="color: #666666"
                v-show="item.status == 1"
              >
                审核成功
              </div>
              <div
                class="loop_over2"
                style="color: #008417"
                v-show="item.status == 2"
              >
                审核失败
              </div>
            </div>
          </div>
          <el-pagination
            class="page"
            :page-size="limit"
            background
            layout="prev, pager, next"
            :total="count"
            :current-page="page"
            @current-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 遮罩层 -->
    <div class="zhe" v-show="tixian_tan"></div>
    <!-- 弹窗 -->
    <div class="tan" v-show="tixian_tan">
      <div class="tan_title">提现信息</div>
      <div class="tan_top">
        <div class="tan_flex">
          <div class="tan_left">提现时间:</div>
          <div class="tan_right">
            {{ (info.create_at * 1000) | format("YYYY-MM-DD HH:MM") }}
          </div>
        </div>
        <div class="tan_flex">
          <div class="tan_left">提现金额:</div>
          <div class="tan_right secolor">{{ info.amount / 100 }}</div>
        </div>
        <div class="tan_flex">
          <div class="tan_left">提现卡号:</div>
          <div class="tan_right">{{ info.bank_num }}</div>
        </div>
        <div class="tan_flex">
          <div class="tan_left">提现银行:</div>
          <div class="tan_right">{{ info.bank }}</div>
        </div>
        <div class="tan_flex">
          <div class="tan_left">审核状态:</div>
          <div class="tan_right">
            {{
              info.status == 0
                ? "审核中"
                : info.status == 1
                ? "审核成功"
                : "审核失败"
            }}
          </div>
        </div>
        <div class="tan_flexs" v-show="info.status == 2">
          <div class="tan_left">失败原因:</div>
          <div class="hui">
            {{ info.refuse_reason }}
          </div>
        </div>
        <button class="suc" @click="suc">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getWithdrawRecord } from "@network/withdraw";
export default {
  name: "WithdrawDetailed",
  data() {
    return {
      record: [],
      valueStart: "",
      valueEnd: "",
      tixian_tan: false,
      id: "",
      page: 1, //页码
      limit: 10,
      info: {},
      count: 0,
      startDate: "", //开始时间 传递的
      endDate: "", //结束时间 传递的
    };
  },
  created() {
    this.getWithdrawRecord();
  },
  components: {},
  methods: {
    format(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    //选择开始时间
    chooseStart() {
      this.startDate =
        new Date(this.valueStart).getFullYear() +
        "" +
        this.format(new Date(this.valueStart).getMonth() + 1) +
        "" +
        this.format(new Date(this.valueStart).getDate());
      this.record = [];
      this.page = 1;
      this.getWithdrawRecord();
    },
    //选择结束时间
    chooseEnd() {
      this.endDate =
        new Date(this.valueEnd).getFullYear() +
        "" +
        this.format(new Date(this.valueEnd).getMonth() + 1) +
        "" +
        this.format(new Date(this.valueEnd).getDate());
      this.record = [];
      this.page = 1;
      this.getWithdrawRecord();
    },
    //处理当前页变动时触发的事件
    handleSizeChange(val) {
      this.page = val;
      this.getWithdrawRecord();
    },
    //获取提现记录
    getWithdrawRecord() {
      getWithdrawRecord(
        this.page,
        this.limit,
        this.startDate,
        this.endDate
      ).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({message:"请重新登录",offset: 300});
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取记录失败",
            offset: 300
          });
          return false;
        } else {
          this.count = res.count;
          this.record = res.data;
        }
      });
    },
    // 确定
    suc() {
      this.tixian_tan = false;
    },
    // 点击列表 获取记录详情
    xiansuc(id) {
      console.log(id);
      this.record.forEach((item) => {
        if (item.id == id) {
          this.info = item;
        }
      });
      this.tixian_tan = true;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/withdrawdetailed.less";
</style>
